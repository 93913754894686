<template>
    <div class="panel" id="animation-panel">
        <div class="wrapper">
            <img class="elevator-img" id="elevator-img" v-if="monitorPageDisplay.doorStatus.value == 2" src="@/assets/ic_elevator_open.png">
            <img class="elevator-img" id="elevator-img" v-else-if="monitorPageDisplay.doorStatus.value == 0" src="@/assets/ic_elevator_open.png">
            <img class="elevator-img" id="elevator-img" v-else-if="monitorPageDisplay.doorStatus.value == 3" src="@/assets/ic_elevator_open.png">
            <img class="elevator-img" id="elevator-img" v-else src="@/assets/elevator.png">
            <div class="dt-floor">
                <img style="width:50%; height:auto;" v-if="monitorPageDisplay.direction.value == 1" src="@/assets/ic_up.png">
                <img style="width:50%; height:auto;" v-else-if="monitorPageDisplay.direction.value == 2" src="@/assets/ic_down.png">
                <img style="width:50%; height:auto;" v-else src="@/assets/ic_floor.png">
                <div>{{monitorPageDisplay.floor.value}}</div>
            </div>
            <div class="dt-no">{{elevator.v_elevator_name}}</div>
        </div>
    </div>
</template>
<script>
import Monitor from '@/pages/monitor/monitor'
export default {
    data() {
        return {}
    },
    props: ['elevator'],
    mixins: [Monitor],
    mounted() {
        //监听元素变化

        this.initSocket(this.elevator.v_elevator_code, ['CarRoof', 'MotorRoom', 'SINGLEBOX'])
    },
    methods: {
        getElevatorCode() {
            return this.elevator.v_elevator_code
        }
    },
    watch: {
        // elevatorCode(newVal,oldVal) {
        //     debugger
        //     console.log(newVal, oldVal)
        // }
    }
}
</script>
<style lang="less" scoped>
.panel {
    display: flex;
    justify-content: center;
}

.wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .dt-no {
        position: absolute;
        bottom: 0;
        width: 100%;
        font-size: 16px;
        color: #fff;
        height: 11%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dt-floor {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: 0;
        width: 29%;
        color: #fff;
        height: 11%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;

        div {
            font-size: 15px;
            color: red;
        }
    }
}

.elevator-img {
    height: 100%;
    width: 100%;
}
</style>